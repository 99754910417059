/* eslint-disable no-unused-vars */
import { useTheme } from '@mui/material/styles';
import {
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationSection from './NotificationSection';
import ProfileSection from './ProfileSection';
import { main_routes_items } from 'routes/MainRoute';
import HeaderItem from './HeaderItem';
import { lion_image } from 'assets/images';

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(() => ({
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  borderBottom: '1px solid #DEE2E6',
  background: `url(${lion_image}) -20px 0px , linear-gradient(90deg, #14521E 0%, #1D674E 54.24%, #265F67 100%)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain'
}));

const Header = ({
  open,
  handleDrawerToggle
}: {
  open: boolean;
  handleDrawerToggle: () => void;
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const mainHeader = (
    <Stack>
      <Toolbar sx={{ height: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: 1, height: 72 }}
        >
          {matchDownMD && (
            <IconButton onClick={handleDrawerToggle}>
              <MenuOpenIcon />
            </IconButton>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={matchDownMD ? 'center' : 'space-between'}
            width={1}
            px={{ md: '121px', bgcolor: 'red' }}
          >
            <Typography
              textAlign="center"
              color="#FFF"
              variant="h1"
              textTransform="capitalize"
            >
              SIPA-ADMIN {matchDownMD && <br />}
              <Typography component="span" fontSize={20} color="#E4E4E7">
                Portail Administrateur
              </Typography>
            </Typography>
            {!matchDownMD ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <NotificationSection />
                <ProfileSection />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Toolbar>
      {!matchDownMD ? (
        <Stack
          px={{ md: '150px' }}
          direction="row"
          alignItems="center"
          bgcolor="#FFF"
        >
          {main_routes_items.map((item) => (
            <HeaderItem key={item.id} item={item} />
          ))}
        </Stack>
      ) : null}
    </Stack>
  );

  // app-bar params
  const appBar: any = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      px: { xs: '0px', md: '15px' }
    }
  };

  return (
    <AppBarStyled open={open} {...appBar}>
      {mainHeader}
    </AppBarStyled>
  );
};

export default Header;
