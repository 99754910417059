// types
import { createSlice } from '@reduxjs/toolkit';
import Program from 'models/program.model';
// import { AppDispatch } from '.';

export const initialState = { data: {} } as any;

// ==============================|| SLICE - MENU ||============================== //

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    listRequest(state, { payload }) {
      const items: Record<string, any> = {};
      payload.forEach((item: Program) => {
        items[item._id + ''] = item;
      });
      state.data = items;
    },
    addRequest(state, { payload }) {
      state.data[payload._id] = {
        ...payload
      };
    }
  }
});

export default requestSlice.reducer;

export const { listRequest, addRequest } = requestSlice.actions;
