/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import { Navigate } from 'react-router-dom';
import EvaluationIcon from 'assets/icons/drawer/EvaluationIcon';
import AllProgramIcon from 'assets/icons/drawer/AllProgramIcon';
import ProgramIcon from 'assets/icons/drawer/ProgramIcon';
import ProfileIcon from 'assets/icons/drawer/ProfileIcon';
import WaIcon from 'assets/icons/drawer/WaIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import PhoneIcon from 'assets/icons/drawer/PhoneIcon';

const Page404 = Loadable(lazy(() => import('../pages/404/NotFound')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Notifications = Loadable(
  lazy(() => import('../pages/notifications/Notifications'))
);

const Applicants = Loadable(
  lazy(() => import('../pages/applicants/Applicants'))
);
const ApplicantsDetails = Loadable(
  lazy(() => import('../pages/applicants/ApplicantsDetails'))
);
const Users = Loadable(lazy(() => import('../pages/user/User')));
const Faqs = Loadable(lazy(() => import('../pages/faqs/Faqs')));
const ContactUs = Loadable(lazy(() => import('../pages/contactUs/ContactUs')));

const Programmes = Loadable(
  lazy(() => import('../pages/programmes/Programmes'))
);
const ProgrammeForm = Loadable(
  lazy(() => import('../pages/programmes/components/ProgrammeForm'))
);
const ProgrammeDetails = Loadable(
  lazy(() => import('../pages/programmes/ProgrammeDetails'))
);

const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));

export const main_routes_items: any[] = [
  // {
  //   id: 1,
  //   path: '/evaluation',
  //   name: 'evaluation',
  //   title: 'evaluation',
  //   icon: EvaluationIcon
  // },
  // {
  //   id: 2,
  //   path: '/all_program',
  //   name: 'all_program',
  //   title: 'tous les programmes',
  //   icon: AllProgramIcon
  // },
  // {
  //   id: 3,
  //   path: '/programs',
  //   name: 'programs',
  //   title: 'vos programmes',
  //   icon: ProgramIcon
  // },
  // {
  //   id: 4,
  //   path: '/profile',
  //   name: 'profile',
  //   title: 'profile de la companie',
  //   icon: ProfileIcon
  // },
  {
    id: 1,
    path: '/dashboard',
    name: 'dashboard',
    title: 'dashboard',
    icon: EvaluationIcon
  },
  {
    id: 2,
    path: '/programmes',
    name: 'programmes',
    title: 'programmes',
    icon: AllProgramIcon
  },
  {
    id: 3,
    path: '/applicants',
    name: 'applicants',
    title: 'Evaluations',
    icon: ProfileIcon
  },
  {
    id: 4,
    path: '/users',
    name: 'users',
    title: 'users',
    icon: ProfileIcon
  }
];

export const faqLink = {
  id: 5,
  path: '/faq',
  name: 'faq',
  title: 'FAQs',
  icon: WaIcon
};

export const contactLink = {
  id: 7,
  path: '/contact',
  name: 'contact',
  title: 'Contactez-nous',
  icon: PhoneIcon
};

export const notifLink = {
  id: 6,
  path: '/notifications',
  name: 'notifications',
  title: 'notifications',
  icon: () => <NotificationIcon fill="#333333" />
};

const main_routes = [
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Page404 />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" />, index: true },
      // { path: '/evaluation', element: <Evaluation /> },

      { path: '/dashboard', element: <Dashboard /> },
      { path: '/programmes', element: <Programmes /> },
      { path: '/programmes/create', element: <ProgrammeForm /> },
      { path: '/programmes/create/:programID', element: <ProgrammeForm /> },

      { path: '/programmes/:programID', element: <ProgrammeDetails /> },
      { path: '/applicants', element: <Applicants /> },
      { path: '/applicants/:applicantID', element: <ApplicantsDetails /> },
      { path: '/users', element: <Users /> },
      { path: '/notifications', element: <Notifications /> },
      { path: '/faq', element: <Faqs /> },
      { path: '/contact', element: <ContactUs /> }
    ]
  }
];

export default main_routes;
