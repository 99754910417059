// types
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {} as any;

// ==============================|| SLICE - USER ||============================== //

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUser(state, { payload }) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = items;
      return state;
    },
    addUser: (state, { payload }) => {
      console.log('the payload', payload);
      state.data[payload._id] = payload;
      return state;
    }
  }
});

export default userSlice.reducer;

export const { addUser, initUser } = userSlice.actions;
