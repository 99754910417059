import Program from 'models/program.model';
import api from '.';
import { initProgram } from 'store/program';

export const programeAPI = api.injectEndpoints({
  endpoints: (build) => ({
    allProgram: build.query<Program, void>({
      query: () => ({ url: '/programs' }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            // const data = res.data;
            dispatch(initProgram(res.data));
          })
          ?.catch((err) => {
            console.warn('get programe error', err);
          });
      }
    }),
    createProgram: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/programs',
        method: 'POST',
        body: data
      })
    }),
    modifyProgram: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/programs/' + data?._id,
        method: 'PUT',
        body: data
      })
    })
  })
});

export const {
  useAllProgramQuery: useAllProgramAsync,
  useCreateProgramMutation: useCreateProgramsAsync,
  useModifyProgramMutation: useModifyProgramAsync
} = programeAPI;

export default programeAPI;
