import { IconButton } from '@mui/material';

import NotificationIcon from 'assets/icons/NotificationIcon';
export default function NotificationSection() {
  return (
    <IconButton>
      <NotificationIcon />
    </IconButton>
  );
}
