// third-party
import api from '../api';
import localforage from 'localforage';
import uiReducer, { initialState as uiInit } from './ui';
import { persistReducer, persistStore } from 'redux-persist';
import authReducer, { initialState as authInit } from './auth';
import programReducer, { initialState as programInit } from './program';
import userReducer, { initialState as userInit } from './userSlice';
import applicantReducer, {
  initialState as applicantInit
} from './applicantSlice';
import requestReducer from './request';
import { combineReducers, configureStore, AnyAction } from '@reduxjs/toolkit';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: 'sympa-admin-',
  storage: localforage,
  blacklist: [api.reducerPath]
};

const initialState: Record<string, any> = {
  ui: uiInit,
  auth: authInit,
  programs: programInit,
  user: userInit,
  applicant: applicantInit
};

const reducers = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  programs: programReducer,
  user: userReducer,
  applicant: applicantReducer,
  requests: requestReducer,
  [api.reducerPath]: api.reducer
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'USER_LOGGED_OUT') {
    return { ...initialState };
  }
  return reducers(state, action);
};
const persistReducers = persistReducer(persistConfig, rootReducer);

export const store: any = configureStore({
  reducer: persistReducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootStateReturn = () => RootState;
// eslint-disable-next-line no-unused-vars
export declare type Callback = (...args: any[]) => void;

export default persistStore(store);
// export default store;
