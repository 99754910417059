const textFR = {
  french: 'français',
  english: 'anglais',
  login: 'Se connecter',
  email: 'Email',
  user: 'Utilisateur',
  password: 'Mot de passe',
  typeMail: "Saisir l'adresse email et le mot de passe pour se connecter",
  rememberMe: 'Se souvenir de moi',
  forgottenPassword: 'mot de passe oublié',
  authenticate: 'Authentifier',
  dataLoading: 'Chargement des données',
  dashboard: 'tableau de bord',
  see: 'Voir',
  admin: 'Administrateur',
  results: 'Resultat',
  next: 'Suivant',
  previous: 'Précédent',
  save: 'Enregistrer',
  profil: 'Profile',
  settings: 'Paramètres',
  updateAccount: 'Mise à jour du compte',
  viewProfile: 'Voir le Profile',
  logOut: 'Deconnexion',
  help: 'Aide',
  names: 'Nom',
  phoneNum: 'Numéro de téléphone',
  cancel: 'annuler',
  all: 'Tout',
  statut: 'Status',
  loading: 'Chargement...',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  valider: 'Valider',
  // other
  driver_name: 'Nom du livreur',
  driver_ID: ' ID du Livreur',
  contact: 'contact',
  registrationDate: 'Date de création',
  deliveries: 'Livraisons',
  payOut: 'payer',
  username: "nom d'utilisateur",
  filter: 'filtrer',
  report_statistics: 'Rapport & Statistique',
  feedback: "retour d'informations",
  subscription: 'subscription',
  restaurant_name: 'Nom du Restaurant',
  location: 'location',
  last_renewal: 'dernier renouvellement',
  expiry_date: "Date d' expiration",
  view: 'Voir',
  active: 'actif',
  inactive: 'inactif',
  new: 'Nouveau',
  app_users: "Utilisateurs de l'app",
  foodMenu: 'Menu',
  staff: 'Employés',
  profile: 'Profile',
  reviews: 'Commentaires',
  totalsMenus: 'menu complet',
  totalsRiders: 'Nombre total de livreurs',
  ongoingDeliveries: 'Livraison',
  completeDeliveries: 'Livraison complete',
  total_deliveries: 'total de livraisons',
  all_restaurants: 'tous les restaurants',
  restaurant_detail: 'Detail sur le restaurant',
  MostOrderedMeals: 'Repas les plus commandés',
  last_number_days: '#number derniers jours',
  starters: 'débutants',
  main_courses: 'Plats principaux',
  no_items_found: 'aucuns éléments trouvés',
  items: 'éléments',
  description: 'Description',
  available: 'Disponible',
  unit_price: 'prit unitaire',
  last_updated: 'dernière mise à jour',
  staff_name: "Nom de l'employé",
  position: 'poste',
  access: 'accès',
  date_added: 'date de création',
  closed: 'fermé',
  companyAvailabilities: "disponibilités de l'entreprise",
  companyInfos: 'Information sur le restaurant',
  paymentMethod: 'Méthode de paiement',
  security: 'Securité',
  companyType: "Type d'entreprise",
  email_address: 'addresse email',
  address_line: 'address line',
  owner_name: 'nom du propriétaire',
  company_contact: "contact de l'entreprise",
  open_time: 'période ouverte',
  define_way_for_client_to_pay: 'définir un mode de paiement pour le client',
  password_access: 'mot de passe , accès',
  credit_card: 'carte de crédit',
  mobile_money: 'mobile money',
  other_payment_method: 'autre mode de paiement',
  your_rating: 'votre commentaire',
  overall_rating: 'note globale',
  rating_chart: "tableau d'évaluation",
  customer_reviews: 'avis des clients',
  spent: 'dépensée(s)',
  drivers: 'livreurs',
  orders: 'commandes',
  total_sales: 'ventes total ',
  global_total: 'total global',
  total_orders: 'commande(s) totale(s)',
  total_preparing: 'total en cours',
  total_successful: 'total reussi',
  total_cancelled: 'total echoué',
  total_services_fee: 'Total des frais',
  delivery: 'livraison',
  total_ongoing: 'total en cours',
  top_customers: 'meilleur Utilisateur',
  top_restaurants: 'meilleur Restaurants',
  top_drivers: 'meilleur Livreurs',
  sales: 'ventes',
  earnings: 'revenues',
  add_content: 'ajoutez du contenu',
  content_desc: 'le contenu va apparaitre dans le carousel',
  recommended_meals: 'Repas Recommendés',
  add_meal: 'Ajouter un repas',
  no_order_today: "pas de commande aujourd'hui",
  notification_image: 'image de notification',
  click_to_upload: 'cliquez pour télécharger',
  drag_desc: 'ou glisser-dépose',
  png_required: 'PNG, JPG | Taille requise',
  size_desc: '350x132px',
  legal_text: 'texte legal',
  content_title: 'titre du contenu',
  content_text: 'texte du contenu',
  no_order_to_day: 'aucunes commande trouvée(s)',
  total_transaction: 'nombre total transactions',
  all_driver: 'Livreurs',
  last_3_days: '3 derniers jours',
  totalMenus: 'total menus',
  ongoing_deliveries: 'livraisons en cours',
  completed_deliveries: 'Livraisons complètes',
  lastUpdated: 'dernières mises à jour',
  customers_reviews: 'Commentaires des clients',
  no_reviews: 'aucuns commentaires',
  no_staff: 'aucuns employés',
  all_restaurant: 'all restaurant',
  restaurant_details: 'details du restaurant',
  new_rider: ' Nouveau Livreur',
  firstname: 'nom',
  lastname: 'prénom',
  driver_desc:
    "Le chauffeur accèdera à l'application de livraison avec ce numéro. Assurez-vous qu'il s'agit du bon numéro.",
  restaurant_desc:
    "Le restaurant accèdera à l'application  d' administration avec ce numéro. Assurez-vous qu'il s'agit du bon numéro.",
  transport_type: 'type de transport',
  number_plate: "plaque d'immatriculation",
  create_driver_desc: 'Ajouter un nouveau livreur qui aura accès au système.',
  statistic: 'statistiques',
  order_history: 'historiques des commandes',
  total_earnings: 'total des revenus',
  requested_on: 'demandée le',
  total_delivered: 'total livraison',
  total_coast: 'total',
  order_id: 'ID com',
  customer: 'client',
  delivery_address: 'addresse de livraison',
  order_detail: 'détail de la commande',
  delivery_fee: 'frais de livraison',
  estimated_time: 'durée estimée',
  no_order: 'aucune commandes',
  search: 'rechercher',
  registered_on: 'enregistré le',
  driver: 'Livreur',
  DELIVERED: 'livrée',
  CANCELLED: 'annuler',
  NEW: 'nouveau', // hen we have just published the order
  READY: 'preparée', // when food is being prepared
  PREPARING: 'en cuisine', // when food has been prepared and waiting driver
  ON_ROUTE: 'en route', // when the driver is en route for delivery
  rider_cancelled: 'livraison annulée par le livreur',
  device_preview: "aperçu sur l'appareil",
  device_preview_desc:
    'Cet aperçu donne une idée générale de la façon dont votre message apparaîtra sur un appareil mobile.',
  remove: 'retirer',
  edit_content: 'modfier',
  no_driver: 'aucuns livreur(s)',
  no_app_users: 'aucun(s) utilisateur(s) trouvé(s)',
  add_restaurant_desc: 'Créez un nouveau restaurant qui aura accès au système.',
  restau_name: 'nom du restaurant',
  add_restaurant: 'Ajouter un restaurant',
  business_location: 'localisation du restaurant',
  phone_number: 'téléphone',
  no_meals_found: 'Aucun(s) de repa(s) trouvés',
  to_day: "aujourd'hui",
  hot_deals: 'les plus commandés',
  ordered_meals: 'repas commandés',
  earning: 'gains',
  earned: 'gagnées',
  active_orders: 'commandes actives',
  user_details: "informations de l'utilisateur",
  payment_options: 'méthodes de paiement',
  no_payment_options: 'aucunes méthodes de paiement',
  last_order: 'dernière commande',
  join_on: 'crée le',
  opened: 'ouvert',
  campaign: 'campagne',
  total_spent: 'total dépensé',
  customers: 'clients',
  id_no: 'identifiant',
  name: 'nom',

  app_settings: "paramètres de l'app",
  category_tags: 'étiquette de la catégorie',
  category_tags_desc: 'Étiquette de repas pour une meilleure recherche',
  hot_deals_and_new_meals: 'Offres spéciales et nouveaux repas',
  hot_deals_and_new_meals_desc: 'Le temps pour ces offres',
  rating_word: 'mot de la note',
  rating_words: "mots d'évaluation",
  rating_words_desc: "Définir les mots pour l'évaluation",
  reasons: 'raisons',
  reasons_desc: "Pour l'annulation d'une commande par un restaurant",
  menu_categories: 'catégories de menus',
  menu_categories_desc: 'Definir les catégories',
  selected_tags: 'étiquettes sélectionnées',
  last_update: 'dernière mise à jour',
  thumbnail: 'vignette',
  tag_name: "nom de l'étiquette",
  new_category_tags: 'nouvelle étiquette de catégorie',
  new_category_tags_desc: 'Ajouter des étiquettes et des vignettes',
  categories: 'categories',
  add: 'ajouter',
  delete: 'supprimer',
  hot_deals_and_new_meals_duration:
    'durée maximale offres spéciales et des nouveaux repas ',
  hot_deals_max_duration: 'durée maximale offres spéciales ',
  new_meal: 'nouveau repas',
  days: 'jour(s)',
  week: 'semaine(s)',
  month: 'mois',
  update: 'mettre à jour',
  word: 'mot',
  new_rating_word: "nouveau mot d'évaluation",
  new_rating_word_desc: "Ajouter des nouveaux mots d'évaluation",
  new_word: 'nouveau mot',
  category: 'categorie',
  sub_category: 'sous categorie',
  new_menu_category: 'Nouvelle catégorie de menu',
  edit_menu_category: 'mettre à jour la catégorie du menu',
  edit_menu_category_desc:
    'modifier les informations et cliquer sur enregistrer pour mettre à jour la catégorie de menu',
  new_menu_category_desc: 'Saisir le nom du menu et les sous-catégories',
  category_group: 'groupe de catégorie',
  click_to_add: 'cliquer',
  number: 'nombre',
  edit_category_tag: "Mettre à jour 'étiquette",
  edit_category_tag_desc: " editer pour mettre à jour l'étiquette",
  block_driver: 'bloquer le livreur',
  block_driver_desc: 'Êtes-vous sûr de vouloir bloquer ce livreur ?',
  yes_block: 'oui , bloquer',
  applicants: 'demandeurs',
  users: 'utilisateurs',
  no_users: "pas d'utilisateurs",
  new_system_user: 'nouvel utilisateur système',
  new_system_user_desc: 'Créer un utilisateur qui accèdera au système',
  surname: 'prenom',
  username_desc: 'Nom d’utilisateur',
  confirm_the_password: 'confirmez le mot de passe',
  ministrie: 'ministère',
  ministrie_departemnt: 'Ministere / Département',
  last_connection: 'dernière connection',
  connections: 'connections',
  status: 'statut',
  connected: 'connecté',
  disconnected: 'déconnecté',
  system_user_det: "Détails de l'utilisateur système",
  number_logins: 'nombre de connexions',
  social_raison: 'Désignation ou raison sociale',
  cni_number: 'Numero CNI/Electeur*',
  email_2: 'Adresse e-mail',
  gender: 'genre',
  roles: 'rôles',
  no_programme: 'pas de programme',
  all_programmes: 'Les programmes',
  new_programme: 'Nouveau programme',
  new_programme_desc: "création d'un nouveau programme",
  programe_info: 'informations sur le programme',
  programme_detail: 'détails du programme',
  eligibility_criteria: 'critères de qualification',
  programme_name: 'nom du programme',
  financement_object: "Objectif de l'accompagnement",
  eligible_activity: "secteurs d'activité éligibles",
  separate_comma_desc:
    'séparez par des virgules pour ajouter plusieurs balises',
  mechanisme_durations: 'durée du mécanisme',
  programme_contact: 'contact du programme*',
  year: 'années',
  section_title: 'titre de section',
  content: 'contenu',
  base_information: 'Informations de base',
  values: 'Valeurs',
  solidar_group_desc: 'faites-vous partie d’un groupe solidaire?',
  pme_desc: 'etes-vous une PME?',
  financial_info: 'informations Financières',
  desir_amount: 'somme desirée',
  financial_garanty: 'garantie financière',
  moral_caution_desc: 'avez-vous une caution morale?',
  financial_declaration_desc:
    "avez-vous une déclaration fiscale de la 1ere année d'activité?",
  manage_rev: 'générez-vous du revenu?',
  other_info: 'autres Informations',
  activity_sector: "secteur d'Activité",
  yes_no: 'oui/Non',
  minimun: 'Minimum',
  Maximun: 'Maximum',
  enter_amount: 'entrer un montant',
  ancient_partner_desc:
    'Êtes-vous ancien client d’un de nos partenaires financiers?',
  last_3_year_state: "Avez vous vos 3 dernières années d'états financiers?",
  relance_plan: 'Avez vous un plan de relance?',
  beneficary_cycle: 'Êtes- vous bénéficiaire en fin de cycle?',
  minimum_exceed_maximun:
    'la valeur minimale ne doit pas être supérieure à la valeur maximale',
  programe_overview: 'aperçu du programme',
  confirm_creation: 'confirmer la création',
  back: 'retour',
  no_applicants: 'pas de demande',
  first_responsability: 'premier responsable',
  company_name: 'nom de l’Entreprise',
  elgible_programe: 'nombre de programmes éligibles',
  manager_info: 'informations du gérant',
  birth_date_manager: 'Date de naissance du gérant',
  manager_name: 'nom du gérant',
  email2: 'adresse e-mail',
  your_compagnie: 'vos compagnies',
  company_formalised: 'Compagnie formalisée',
  type_moral_person: 'Type de personnalité morale',
  activity_generated_rev: 'Activité génératrice de revenue',
  entreprise_identifiant: 'Identifiant Entreprise',
  creation_date: 'Date de création',
  male: 'homme',
  female: 'femme',
  yes: 'oui',
  no: 'non',
  evaluation_date: "Jour d'évaluation",
  confirm_modification: 'confirmer modifications',
  edit_programme: 'modifier un programme',
  edit_programme_desc: "modification d'un programme existant",
  alert_modif_description:
    'cette action va entrainer la perte de tous les changements que vous avez effectuez sur cette page , en etes vous bien sure ?',
  modify_system_user: 'modifier utilisateur système',
  modify_system_user_desc:
    "modifier les informations d'un utilisateur qui accèdera au système",
  reset: 'Reinitialiser',
  validate: 'Valider',
  years: 'années',
  partners: 'partenaires',
  months: 'mois',
  cooperative_group_desc:
    "faites vous partie d'une coopérative ou disposé à faire partie d'une coopérative.",
  age_interval: "intervalle d'âge",
  graduate_from_desc: 'Êtes-vous diplômé du IFAD?',
  formalised_duration: 'durée de formalisation minimale',
  enter_age: "entrez l'âge",
  enter_minimum_duration: 'entrez la durée minimale',
  turnover_last_3years:
    "chiffres d'affaires minimum sur les 3 dernières années",
  native_country: "pays d'origine",
  country: 'pays',
  phoneNumber: 'numéro de téléphone',
  precise_financing_object: "précise l'objet de l'accompagnement",
  more_than_5_years: 'plus de 5 ans',
  other_partners: 'nom du partenaire'
};
export default textFR;
