/* eslint-disable no-unused-vars */
// material-ui

// project import
import Drawer from './Drawer';
import Header from './Header';
import useInit from '../../hook/useInit';
import { openDrawer } from '../../store/ui';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Outlet, Navigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import CircularLoader from 'components/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import CustomBackdrop from 'components/CustomBackdrop';
import { useAllUserAsync } from 'api/userApi';
import { useAllApplicantsAsync } from 'api/applicantApi';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.auth.user);
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch: AppDispatch = useDispatch();

  const { loading } = useInit();

  const userLoad = useAllUserAsync();
  const applicantLoad = useAllApplicantsAsync();

  const { drawerOpen, backdrop } = useSelector((state: RootState) => state.ui);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownMD);
    dispatch(openDrawer({ drawerOpen: !matchDownMD }));
  }, [dispatch, matchDownMD]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen, open]);

  if (!user) {
    return <Navigate to="/login" />;
  }
  if (loading || userLoad.isLoading || applicantLoad.isLoading) {
    return (
      <CircularLoader containerStyle={{ height: '100vh' }} loading={true} />
    );
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CustomBackdrop open={backdrop} />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar sx={{ height: matchDownMD ? 80 : 160 }} />
        <Box
          sx={{
            px: { xs: '20px', md: '90px' },
            pt: '20px',

            height: `calc(100% - ${matchDownMD ? 80 : 180}px)`,
            overflowY: 'auto'
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
