// ==============================|| OVERRIDES - CHIP ||============================== //

export default function ChipOv(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          color: '#6C737F',
          '&:active': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40
        },
        filledSuccess: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.main
        },
        filledWarning: {
          backgroundColor: '#FFF6ED',
          color: '#C4320A'
        },
        filledInfo: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.main
        },

        light: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.success.light,
          borderColor: theme.palette.primary.light,
          '&.MuiChip-lightError': {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.lighter,
            borderColor: theme.palette.error.light
          },
          '&.MuiChip-lightSuccess': {
            color: theme.palette.success.main,
            backgroundColor: theme.palette.success.light,
            borderColor: theme.palette.success.light
          },
          '&.MuiChip-lightWarning': {
            color: theme.palette.warning.main,
            backgroundColor: theme.palette.warning.lighter,
            borderColor: theme.palette.warning.light
          }
        }
      }
    }
  };
}
