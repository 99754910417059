export function capitalizeFirstLetter(value: string) {
  if (value === null || value === undefined || value === '') {
    return '';
  }
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
}

export function capitalizeWords(str: string): string {
  if (str == null || typeof str !== 'string') {
    return '';
  }

  let words = str.split(' ');

  let capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  let result = capitalizedWords.join(' ');

  return result;
}
