import Program from 'models/program.model';
import api from '.';
import { initApplicant, initLogs, initPme } from 'store/applicantSlice';

export const applicantAPI = api.injectEndpoints({
  endpoints: (build) => ({
    allApplicants: build.query<Program, void>({
      query: () => ({ url: '/managers' }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            dispatch(initApplicant(data));
          })
          ?.catch((err) => {
            console.warn('get manager applicants error', err);
          });
      }
    }),
    getPme: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/pmes/filter',
        method: 'POST',
        body: data
      }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            dispatch(initPme(data));
          })
          ?.catch((err) => {
            console.warn('get manager applicants error', err);
          });
      }
    }),
    getLogs: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/logs/filter',
        method: 'POST',
        body: data
      }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            dispatch(initLogs(data));
          })
          ?.catch((err) => {
            console.warn('get manager applicants error', err);
          });
      }
    })
  })
});

export const {
  useAllApplicantsQuery: useAllApplicantsAsync,
  useGetPmeMutation: useGetPmeAsync,
  useGetLogsMutation: useGetLogsAsync
} = applicantAPI;

export default applicantAPI;
