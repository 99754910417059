// types
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n-config';
import { AppDispatch } from '.';

// initial state

export const initialState: any = {
  openItem: ['dashboard'],
  backdrop: false,
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
  sessionExpired: false,
  updatingUser: false,
  updatingProgames: false,
  lang: {
    systemLanguage: 'fr',
    languages: [
      {
        lang: 'english',
        key: 'en'
      },
      {
        lang: 'french',
        key: 'fr'
      }
    ]
  },
  config: {}
};

// ==============================|| SLICE - MENU ||============================== //

const ui = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    toggleSessionExpired: (state, action) => {
      state.sessionExpired = action.payload;
      return state;
    },
    setLanguage: (state, action) => {
      state.lang = { ...state.lang, systemLanguage: action.payload };
      return state;
    },
    updatingProgames: (state, { payload }) => {
      state.updatingProgames = payload;
    },
    updatingUser: (state, { payload }) => {
      state.updatingUser = payload;
    },
    addingCampaigns: (state, { payload }) => {
      state.addingCampaigns = payload;
    },
    closeOpenBackdrop(state, { payload }) {
      state.backdrop = payload;
    },
    listConfig(state, { payload }) {
      state.config = { ...state.config, ...payload };
      return state;
    }
  }
});

export default ui.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  toggleSessionExpired,
  setLanguage,
  addingCampaigns,
  closeOpenBackdrop,
  updatingUser,
  updatingProgames,
  listConfig
} = ui.actions;

export const changeLanguage = (language: string) => (dispatch: AppDispatch) => {
  try {
    // await AsyncStorage.setItem('LANG', language);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  } catch (error) {
    console.log('change language error', error);
  }
};
