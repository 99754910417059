import { Backdrop, CircularProgress } from '@mui/material';

export default function CustomBackdrop({ open = false }: { open?: boolean }) {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        '& :not(style)~:not(style)': {
          marginLeft: 0
        },
        zIndex: (theme) => theme.zIndex.drawer + 4000
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
