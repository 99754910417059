import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Palette from './themes/palette';
import { useCallback, useEffect, useMemo } from 'react';
import CustomShadows from './themes/shadows';
import ComponentsOverrides from './themes/overrides';
import router from 'routes';
import { RouterProvider } from 'react-router-dom';
import Typography from './themes/typographyTheme';
import './i18n-config';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useDispatch } from 'react-redux';
import { changeLanguage } from './store/ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const theme = Palette('light');
  const dispatch = useDispatch();

  const lang = useSelector((state: RootState) => state.ui.lang);

  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536
        }
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: Typography(`sans-serif`)
    }),
    [theme, themeCustomShadows]
  );

  const themes = createTheme(themeOptions as any);
  themes.components = ComponentsOverrides(themes);

  const changeLang = useCallback(() => {
    dispatch(changeLanguage(lang?.systemLanguage) as any);
  }, [dispatch, lang?.systemLanguage]);

  useEffect(() => {
    changeLang();
  }, [changeLang]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        <RouterProvider router={router} />
        <ToastContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
