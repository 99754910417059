// ==============================|| DRAWER CONTENT ||============================== //

// import NavItem from './NavItem';
import i18n from '../../../i18n-config';
import { RootState } from '../../../store';
import { Box, Button, Stack } from '@mui/material';
import SimpleBar from '../../../components/SimpleBar';
import { useSelector, useDispatch } from 'react-redux';

import LogoutIcon from '../../../assets/icons/LogoutIcon';
import NavItem from './NavItem';
import {
  contactLink,
  faqLink,
  main_routes_items,
  notifLink
} from 'routes/MainRoute';

const DrawerContent = () => {
  const { drawerOpen } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  // const user = useSelector((state: RootState) => state.auth.user);
  // console.log(user);
  return (
    <SimpleBar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          pt: '53px',
          height: 'calc(100vh - 80px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack spacing={1} px={2}>
          {main_routes_items.map((item, i) => (
            <NavItem key={i} item={item} level={0.5} />
          ))}
          <NavItem item={notifLink} level={0.5} />
        </Stack>
        <Stack px={3}>
          <NavItem item={faqLink} level={0.5} />
          <NavItem item={contactLink} level={0.5} />
          <Button
            variant="text"
            startIcon={<LogoutIcon />}
            sx={{
              width: '100%',
              color: '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              textAlign: 'left',
              pl: 2
            }}
            onClick={() => dispatch({ type: 'USER_LOGGED_OUT' })}
          >
            {drawerOpen ? (
              <span style={{ marginLeft: '20px' }}> {i18n.t('logout')} </span>
            ) : null}
          </Button>
        </Stack>
      </Box>
    </SimpleBar>
  );
};

export default DrawerContent;
