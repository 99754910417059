import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activeItem } from 'store/ui';

import { Button } from '@mui/material';
import { RootState } from 'store';
import { capitalizeFirstLetter } from 'utils/capitalize';
import i18n from 'i18n-config';

export default function HeaderItem({ item }: { item: any }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { openItem } = useSelector((state: RootState) => state.ui);

  const itemHandler = (id: string | number) => {
    navigate(item?.path);
    dispatch(activeItem({ openItem: [id] }));
  };

  const isSelected =
    openItem.findIndex((id: string | number) => id === item.id) > -1;
  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.path)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
    }, [pathname]);
  return (
    <Button
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      sx={{
        height: 72,
        width: 'auto',
        borderRadius: 0,
        minWidth: 170,
        overflow: 'hidden',
        color: isSelected ? 'primary' : '#3F3F46',
        fontWeight: isSelected ? 700 : 400,
        fontsize: 16,
        borderBottom: isSelected ? '4px solid #43A842' : 'none',
        '&:hover': {
          bgcolor: 'transparent',
          borderBottom: '4px solid #43A842'
        }
      }}
    >
      {capitalizeFirstLetter(i18n.t(item.title))}
    </Button>
  );
}
