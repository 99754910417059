// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonOv() {
  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: '#F4F4F5',
      color: '#75757750'
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: '8px',
          height: '43px',
          px: '20px',
          textTransform: 'none'
        },
        contained: {
          ...disabledStyle
        },
        outlined: {
          ...disabledStyle
        }
      }
    }
  };
}
