// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

// import FONTS from './font';

const Typography = (fontFamily: string) => ({
  htmlFontSize: 14,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 500,
    fontSize: '24px',
    color: '#27272A'
  },
  h2: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 1.27,
    color: '#27272A'
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: 1.33
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.4
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5
  },
  h6: {
    fontWeight: 600,
    fontSize: '16px'
  },
  caption: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.66
  },

  body1: {
    fontSize: '16px',
    color: '#333333',
    fontWeight: 500
  },
  body2: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.66
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 400
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 400
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'capitalize'
  }
});

export default Typography;
