/* eslint-disable no-unused-vars */
import { forwardRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { RootState } from '../../../store';
import { activeItem } from '../../../store/ui';
import i18n from '../../../i18n-config';
import { capitalizeFirstLetter } from 'utils/capitalize';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }: { item: any; level: any }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { drawerOpen, openItem } = useSelector((state: RootState) => state.ui);

  let listItemProps = {
    component: forwardRef((props, ref: any) => (
      <Link ref={ref} {...props} to={item.path} />
    ))
  };

  const itemHandler = (id: string | number) => {
    navigate(item?.path);
    dispatch(activeItem({ openItem: [id] }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    false
  );

  const isSelected =
    openItem.findIndex((id: string | number) => id === item.id) > -1;
  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.path)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  // const textColor = 'text.primary';
  const iconSelectedColor = '#FFFFFF';

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 0,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        fontWeight: 400,
        borderRadius: 2,
        overflow: 'hidden',
        '&:hover': {
          bgcolor: '#43A84250'
        },
        '&.Mui-selected': {
          bgcolor: '#43A84250',
          color: iconSelectedColor,
          '&:hover': {
            color: iconSelectedColor,
            bgcolor: '#43A84250'
          }
        }
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: '#FFFFFF',
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              fontSize: 30,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'secondary.lighter'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: 'primary.lighter',
                '&:hover': {
                  bgcolor: 'primary.lighter'
                }
              })
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {drawerOpen && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color: '#333',
                fontSize: 16,
                fontWeight: 400,
                ml: '20px',
                width: 1,
                overflow: 'hidden'
              }}
            >
              {capitalizeFirstLetter(i18n.t(item.title))}
            </Typography>
          }
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
