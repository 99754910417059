// types
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '.';

export const initialState = {
  user: null,
  temporal: {},
  idToken: null
} as any;

// ==============================|| SLICE - MENU ||============================== //

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth(state, { payload }) {
      state.user = payload.user;
      state.idToken = payload.token;
    },
    addTemp(state, { payload }) {
      state.temporal = {
        ...state.temporal,
        ...payload
      };
    },
    addUuid: (state, { payload }) => {
      state.user.uuid = payload;
    },
    refreshToken: (state, { payload }) => {
      console.log('on refresh token slice', payload);
      state.idToken = payload;
    }
  }
});

export default authSlice.reducer;

export const { initAuth, addUuid, addTemp, refreshToken } = authSlice.actions;

export const onLogout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'USER_LOGGED_OUT' });
  } catch (error) {
    console.log('logout error', error);
  }
};
