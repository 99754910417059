/* eslint-disable react/no-unknown-property */
export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13 1.75001C13.9577 1.75001 14.3492 1.75234 14.6648 1.80233C16.4834 2.09036 17.9096 3.51662 18.1977 5.33516C18.2477 5.65083 18.25 6.04234 18.25 7.00001V13C18.25 13.9577 18.2477 14.3492 18.1977 14.6649C17.9096 16.4834 16.4834 17.9097 14.6648 18.1977C14.3492 18.2477 13.9577 18.25 13 18.25H12C11.0424 18.25 10.1607 17.9342 9.45043 17.4007C9.11924 17.1519 8.64909 17.2188 8.40032 17.5499C8.15156 17.8811 8.21838 18.3513 8.54957 18.6C9.51065 19.3219 10.7064 19.75 12 19.75L13.0809 19.75C13.9329 19.7501 14.4518 19.7501 14.8995 19.6792C17.3599 19.2895 19.2895 17.3599 19.6792 14.8995C19.7501 14.4518 19.7501 13.9328 19.75 13.0808V6.91921C19.7501 6.06718 19.7501 5.54819 19.6792 5.10051C19.2895 2.64013 17.3599 0.710485 14.8995 0.320799C14.4518 0.249897 13.9329 0.249937 13.0809 0.250004L12 0.250008C10.7064 0.250008 9.51065 0.678072 8.54957 1.39997C8.21838 1.64874 8.15156 2.11889 8.40032 2.45008C8.64909 2.78127 9.11924 2.84808 9.45043 2.59932C10.1607 2.06583 11.0424 1.75001 12 1.75001H13Z"
        fill="#333333"
      />
      <path
        d="M11.466 5.41233C11.1414 5.15498 10.6697 5.20947 10.4123 5.53404C10.155 5.85861 10.2095 6.33034 10.534 6.58769L12.297 7.98554C13.001 8.54376 13.483 8.92735 13.8105 9.25001L1 9.25001C0.585786 9.25001 0.25 9.5858 0.25 10C0.25 10.4142 0.585786 10.75 1 10.75L13.8105 10.75C13.483 11.0727 13.001 11.4563 12.297 12.0145L10.534 13.4123C10.2095 13.6697 10.155 14.1414 10.4123 14.466C10.6697 14.7905 11.1414 14.845 11.466 14.5877L13.2648 13.1614C13.9373 12.6283 14.4922 12.1883 14.8875 11.7945C15.2932 11.3904 15.6294 10.9419 15.7208 10.3687C15.7402 10.2466 15.75 10.1234 15.75 10C15.75 9.87662 15.7402 9.75345 15.7208 9.63134C15.6294 9.05817 15.2932 8.60963 14.8875 8.20552C14.4922 7.81173 13.9373 7.37176 13.2648 6.83858L11.466 5.41233Z"
        fill="#333333"
      />
    </svg>
  );
}
