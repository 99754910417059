// material-ui
import { useTheme, SxProps, styled } from '@mui/material/styles';
import { Stack, Box, Typography, Avatar } from '@mui/material';
import { appTitle } from '../../../config';

// import Logo from '../../../../components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, sx }: { theme: any; open: boolean; sx?: SxProps }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 2 : 0),
  ...sx
}));

const DrawerHeader = ({
  open,
  handleDrawerToggle
}: {
  open: boolean;
  handleDrawerToggle: () => void;
}) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar
          variant="rounded"
          onClick={handleDrawerToggle}
          sx={{ bgcolor: '#14521E60', height: 40, width: 40 }}
        ></Avatar>
        {open && (
          <Typography variant="h2" color="#333">
            {appTitle}
          </Typography>
        )}
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
