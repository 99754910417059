import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { lion_image } from 'assets/images';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { appTitle } from 'config';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <Stack height="100vh" bgcolor="#EBEFF4">
    <Stack
      height="50%"
      pt={{ sm: '68px' }}
      width={1}
      sx={{
        background: `url(${lion_image}) -10px 50px , linear-gradient(90deg, #14521E 0%, #1D674E 54.24%, #265F67 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}
    ></Stack>
    <Stack
      sx={{
        height: 1,
        position: 'absolute',
        top: 0,
        width: 1,
        overflow: 'auto',
        pb: '50px'
      }}
      alignItems="center"
      pt={{ sm: '68px', xs: '44px' }}
    >
      <Typography
        variant="h1"
        fontSize={{ sm: '48px' }}
        color="#FFF"
        maxWidth={{ sm: '100%', xs: 170, textAlign: 'center' }}
      >
        {appTitle+"   "}  
        <Typography
          component="span"
          color="#E4E4E7"
          fontSize={{ md: '48px', xs: '20px', px: 2 }}
        >
          Portail D’Administration
        </Typography>
      </Typography>
      <Stack width={1} alignItems="center" justifyContent="flex-start">
        <Box
          sx={{
            borderRadius: '12px',
            boxShadow: '0px 1px 2px 0px rgba(31, 41, 55, 0.08)',
            background: '#FFF',
            width: { xs: '90%', md: '35%' },
            mt: { xs: '30px', md: '100px' }
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  </Stack>
);

export default MinimalLayout;
