// ==============================|| OVERRIDES - RATING ||============================== //

export default function RatingOv(theme) {
  return {
    MuiRating: {
      styleOverrides: {
        root: {
          color: theme.palette.warning.main
        }
      }
    }
  };
}
