// types
import { createSlice } from '@reduxjs/toolkit';
import Program from 'models/program.model';
import { updatingProgames } from './ui';
// import { AppDispatch } from '.';
import { v4 as uuidv4 } from 'uuid';

export const initialState = { data: {} } as any;

// ==============================|| SLICE - PROGRAM ||============================== //

const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    initProgram(state, { payload }) {
      const items: Record<string, any> = {};
      payload.forEach((item: Program) => {
        items[item._id + ''] = item;
      });
      state.data = items;
      return state;
    },
    addProgram: (state, { payload }) => {
      state['data'][payload?._id] = payload;
      return state;
    }
  }
});

export default programSlice.reducer;

export const { initProgram, addProgram } = programSlice.actions;

export const createProgram =
  (
    data?: any,
    callBack?: () => void,
    // eslint-disable-next-line no-unused-vars
    onError?: (er?: any) => void
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(updatingProgames(true));

      const programID = uuidv4();
      delete data.confirmPassword;
      const data_to_save = {
        ...data,
        id: programID,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      // retirer cette partie du code pour que sa donne et decommenter le reste
      setTimeout(() => {
        dispatch(addProgram({ data: data_to_save, id: programID }));
        dispatch(updatingProgames(false));
        callBack?.();
      }, 3000);

      // dispatch(addProgram({ data: data_to_save, id: userID }));
    } catch (error) {
      console.log('Error adding a new Camapign state', error);
      onError?.(error);
    } finally {
      // dispatch(updatingUser(false));
      // callBack?.();
    }
  };
