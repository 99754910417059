// types
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {} as any;

// ==============================|| SLICE - APPLICANT ||============================== //

const ApplicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    initApplicant(state, { payload }) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id + ''] = item;
      });
      state.data = items;
      return state;
    },
    initPme: (state, { payload }) => {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id + ''] = item;
      });
      state.pme = items;
      return state;
    },
    initLogs: (state, { payload }) => {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id + ''] = item;
      });
      state.logs = items;
      return state;
    }
  }
});

export default ApplicantSlice.reducer;

export const { initApplicant, initPme, initLogs } = ApplicantSlice.actions;
