import { useMemo } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
// import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from '../../../config';

interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
  window?: any;
}

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(
    () => <DrawerHeader handleDrawerToggle={handleDrawerToggle} open={open} />,
    [handleDrawerToggle, open]
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1300, bgcolor: 'red' }}
      aria-label="mailbox folders"
    >
      {matchDownMD ? (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              // borderRight: `1px solid ${theme.palette.divider}`,
              boxShadow: 'inherit',
              background: '#FFF'
            }
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      ) : null}
    </Box>
  );
};

export default MainDrawer;
