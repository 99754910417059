import Program from 'models/program.model';
import api from '.';
import { initUser } from 'store/userSlice';

export const userAPI = api.injectEndpoints({
  endpoints: (build) => ({
    allUser: build.query<Program, void>({
      query: () => ({ url: '/users' }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            dispatch(initUser(data));
          })
          ?.catch((err) => {
            console.warn('get users error', err);
          });
      }
    }),
    createUser: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/users',
        method: 'POST',
        body: data
      })
    }),
    modifyUser: build.mutation<any, any>({
      query: (data: any) => ({
        url: '/users/' + data?._id,
        method: 'PUT',
        body: data
      })
    })
  })
});

export const {
  useAllUserQuery: useAllUserAsync,
  useCreateUserMutation: useCreateUserAync,
  useModifyUserMutation: useModifyUserAync
} = userAPI;

export default userAPI;
