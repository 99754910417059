// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function TypographyOverrides(fontFamily: string) {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12,
          fontFamily
        }
      }
    }
  };
}
