// material-ui
import { createTheme } from '@mui/material/styles';

import { PaletteMode } from '@mui/material';

const Palette = (mode: PaletteMode) => {
  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      primary: {
        main: '#1B9647'
      },
      secondary: {
        main: '#FDE047'
      },
      error: {
        main: '#EB5757'
      },
      warning: {
        main: '#D77910',
        contrastText: '#B76E00',
        light: '#EDC89E'
      },
      info: {
        main: '#026AA2',
        light: '#F0F9FF'
      },
      success: {
        main: '#12B76A',
        light: '#ECFDF3'
      },
      text: {
        primary: '#333333',
        secondary: '#8392A1',
        disabled: '#E5E7EB'
      },
      action: {
        disabled: '#E5E7EB'
      },
      divider: '#E6E6E6',
      background: {
        paper: '#F3F4F6',
        default: '#F3F4F6'
      }
    }
  });
};

export default Palette;
