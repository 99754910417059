/* eslint-disable no-unused-vars */
// import { retry } from '@reduxjs/toolkit/query/react';
import Manager from 'models/manager.model';
import { refreshToken } from 'store/auth';
import api from '.';
import { listConfig } from 'store/ui';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, { email: string; password: string }>({
      query: (credentials: { email: string; password: string }) => ({
        url: 'users/login',
        method: 'POST',
        body: credentials
      })
    }),
    verifyCode: build.mutation<
      any,
      { email: string; otpCode: string | number }
    >({
      query: (credentials: { email: string; otpCode: string | number }) => ({
        url: 'users/verify-code',
        method: 'POST',
        body: credentials
      })
    }),
    register: build.mutation<any, any>({
      query: (credentials: any) => ({
        url: '/users/company',
        method: 'POST',
        body: credentials
      })
    }),
    refreshToken: build.mutation<void, string>({
      query: (refreshToken) => ({
        url: 'users/refresh-token',
        method: 'POST',
        body: { refreshToken }
      })
    }),
    getConfig: build.query<any, void>({
      query: () => ({ url: '/settings' }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            
            dispatch(listConfig(data));
          })
          ?.catch((err) => {
            console.warn('get config error', err);
          });
      }
    })
  })
});

export const {
  useLoginMutation: useLoginAsync,
  useVerifyCodeMutation: useVerifyCodeAsync,
  useRegisterMutation: useRegisterAsync,
  useRefreshTokenMutation: useRefreshTokenAsync,
  useGetConfigQuery: useConfigAsync
} = authApi;

export default authApi;
