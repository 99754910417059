export default function AllProgramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.9983 22.75H7.99827C3.37827 22.75 2.51827 20.6 2.29827 18.51L1.54827 10.5C1.43827 9.45001 1.40827 7.90001 2.44827 6.74001C3.34827 5.74001 4.83827 5.26001 6.99827 5.26001H16.9983C19.1683 5.26001 20.6583 5.75001 21.5483 6.74001C22.5883 7.90001 22.5583 9.45001 22.4483 10.51L21.6983 18.5C21.4783 20.6 20.6183 22.75 15.9983 22.75ZM6.99827 6.75001C5.30827 6.75001 4.14827 7.08001 3.55827 7.74001C3.06827 8.28001 2.90827 9.11001 3.03827 10.35L3.78827 18.36C3.95827 19.94 4.38827 21.25 7.99827 21.25H15.9983C19.5983 21.25 20.0383 19.94 20.2083 18.35L20.9583 10.36C21.0883 9.11001 20.9283 8.28001 20.4383 7.74001C19.8483 7.08001 18.6883 6.75001 16.9983 6.75001H6.99827Z"
        fill="#333333"
      />
      <path
        d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
        fill="#333333"
      />
      <path
        d="M12 16.75C9.25 16.75 9.25 15.05 9.25 14.03V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z"
        fill="#333333"
      />
      <path
        d="M13.9995 14.77C13.6295 14.77 13.2995 14.49 13.2595 14.11C13.2095 13.7 13.4995 13.32 13.9095 13.27C16.5495 12.94 19.0795 11.94 21.2095 10.39C21.5395 10.14 22.0095 10.22 22.2595 10.56C22.4995 10.89 22.4295 11.36 22.0895 11.61C19.7495 13.31 16.9895 14.4 14.0895 14.77C14.0595 14.77 14.0295 14.77 13.9995 14.77Z"
        fill="#292D32"
      />
      <path
        d="M10.0007 14.7799C9.97072 14.7799 9.94072 14.7799 9.91072 14.7799C7.17072 14.4699 4.50072 13.4699 2.19072 11.8899C1.85072 11.6599 1.76072 11.1899 1.99072 10.8499C2.22072 10.5099 2.69072 10.4199 3.03072 10.6499C5.14072 12.0899 7.57072 12.9999 10.0707 13.2899C10.4807 13.3399 10.7807 13.7099 10.7307 14.1199C10.7007 14.4999 10.3807 14.7799 10.0007 14.7799Z"
        fill="#292D32"
      />
    </svg>
  );
}
